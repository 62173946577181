/** @jsx jsx */
import { jsx } from "theme-ui"
import PropTypes from "prop-types"
import Footer from "./footer"
import Seo from "../components/seo"

/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

const Layout = ({ children }) => {

  return (
      <div
        style={{
          margin: `0 auto`,
          padding: `0`,
          width: `100vw`
        }}
      >
        <Seo title="Tolka Web Development" />
        <main style={{boxShadow: "1px 1px 50px pink"}}>

          {children}

        </main>

         <Footer />
      </div>

  )
}


Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
