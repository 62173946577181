

const colors = {
  primary: '#ff99cc',
  white: '#ffffff',
  black: '#000000',
  background: "white",
  glow1: '#ff6600',
  glow2: '#ff7233',
  glow3: '#ff7f66',
  glow4: '#ff8c99',
  glow430: '#ff8c9948',
  glow5: '#ff99cc',
}

export default colors
