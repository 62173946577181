/** @jsx jsx */
import  {useState} from "react"
import { jsx, Flex, Text } from "theme-ui"
import { Link } from "gatsby"


import colors from "../gatsby-plugin-theme-ui/colors"
//TODO: wrap nav in list


const links = [
  {
    href: "/",
    text: "Home",
  },
  {
    href: "/blog",
    text: "Blog",
  }
]

const externalLinks = [
  {
    href: "https://gitlab.com/tolka-projects/tolka-theme",
    text: "Tolka WP Theme",
  },
  {
    href: "https://audioc0remaster93833.gatsbyjs.io/",
    text: "Tolka Gatsby Starter",
  },
  {
    href: "https://nuxt-demo-one.netlify.app/",
    text: "Nuxt Sandbox",
  },
  {
    href: "https://next-sandbox-2.vercel.app/",
    text: "Next Sandbox",
  },
]


const TolkaLink = ({ item, moveHighlight, clearHighlight }) => {
  return (
    <Link
      onMouseEnter={moveHighlight}
      onClick={clearHighlight}
      to={item.href}
      target={item.target}
      rel={item.rel}
      sx={{
        padding: ".5rem .5rem .4rem ",
        textDecoration: "none",
        margin: "0 .5rem",
        textTransform: "uppercase",
        letterSpacing: "0.12rem",
        "&:hover": {
          color: "black",
          "@media screen and (min-width: 1000px)": {
            color: "white",
          },
          textDecoration: "none",
        },
      }}
    >
      {item.text}
    </Link>
  )
}

const ExternalLink = ({ item, moveHighlight, clearHighlight }) => {
  return (
    <a
      target="_blank"
      rel="noreferrer"
      href={item.href}
      sx={{
        padding: ".5rem .5rem .4rem ",
        textDecoration: "none",
        margin: "0 .5rem",
        textTransform: "uppercase",
        letterSpacing: "0.12rem",
        textAlign: "center",
        "&:hover": {
          color: "black",
          "@media screen and (min-width: 1000px)": {
            color: "white",
          },
          textDecoration: "none",
        },
      }}
      onMouseMove={moveHighlight}
      onClick={clearHighlight}
    >
      {item.text}
    </a>
  )
}

const Footer = () => {

  const initialHighlight =
    {position: "absolute",
    height: 0,
    width: 0,
    top:0,
    left: 0,
    opacity: 0}

  const [highLight,  setHighlight] = useState(initialHighlight);


  const moveHighlight = (e) => {
    setHighlight(  {
      ...highLight,
      height: e.target.clientHeight,
      width: e.target.clientWidth,
      top: e.target.offsetTop,
      left: e.target.offsetLeft,
      opacity: 1
    });
  }

  const clearHighlight = (e) => {
    setHighlight(  {
      ...highLight,
      ...initialHighlight
    });
  }

return (
  <footer onMouseLeave={clearHighlight}>
    <nav>
      <Flex
        sx={{
          justifyContent: "center",
          alignItems: "center",
          padding: "1rem 1rem .5rem 1rem",
          flexDirection: "column",
          "@media screen and (min-width: 64em)": {
            flexDirection: "row",
          },
        }}
      >
        <div
          className="highlight"
          sx={{
            transition: "all .2s cubic-bezier(0.19, 1, 0.22, 1)",
            zIndex: -1,
            borderRadius: "50px",
            backgroundColor: `${colors.glow3}`,
            position: `${highLight.position}`,
            width: `${highLight.width}px`,
            height: `${highLight.height}px`,
            opacity: `${highLight.opacity}`,
            top: `${highLight.top}px`,
            left: `${highLight.left}px`,
          }}
        ></div>

        {links.map((item, index) => {
          return (
            <TolkaLink
              clearHighlight={clearHighlight}
              item={item}
              key={index}
              moveHighlight={moveHighlight}
            />
          )
        })}

        {externalLinks.map((item, index) => {
          return (
            <ExternalLink
              clearHighlight={clearHighlight}
              item={item}
              key={index}
              moveHighlight={moveHighlight}
            />
          )
        })}

        <Text
          as="p"
          sx={{
            padding: ".5rem",
            margin: "0 .5rem",
            textTransform: "uppercase",
            letterSpacing: "0.12rem",
          }}
        >
          Built with Gatsby
        </Text>
      </Flex>
    </nav>
  </footer>
)}

export default Footer
