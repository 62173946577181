import colors from "./colors"


import {
  borderWidths,
  breakpoints,
  sizes,
  shadows,
  space,
  radii,
  zIndices,
} from "./variables"

const theme = {
  /**
   * ThemeUI settings:
   */
  initialColorModeName: "default",
  useBoxSizing: true,
  useBodyStyles: true,
  useColorSchemeMediaQuery: false,
  useCustomProperties: true,

  /**
   * Variables:
   */
  borderWidths,
  breakpoints,
  sizes,
  colors,
  shadows,
  space,
  radii,
  zIndices,
  // ...typography,




  // text,

  styles: {
    root: {
      fontFamily: "Montserrat",
      lineHeight: "1.5",
      fontWeight: "body",

      "#___gatsby": {
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
      },

      "#___gatsby > div": {
        display: "flex",
        flexGrow: 1,
        flexDirection: "column",
      },

      "#___gatsby  p": {
        pb: 2,
      },
      // anti-pattern: defines a global style
      // h1: { color: 'red.5' },
      pre: {
        overflowWrap: "break-word",
        wordBreak: "break-all",
        backgroundColor: colors.glow5,
        padding: "1rem",
        display: "block",
        whiteSpace: "pre",
        overflow: "scroll"

      },
      a: {
        color: "primary",
        textDecoration: "none",
        ":hover": {
          textDecoration: "underline",
        },
      },
      
    },

    

    h1: {
      fontFamily: "heading",
      fontWeight: "heading",
      lineHeight: "heading",
      m: 0,
      mb: 1,
      fontSize: 6,
      mt: 2,
    },

    h2: {
      fontFamily: "heading",
      fontWeight: "heading",
      lineHeight: "heading",
      m: 0,
      mb: 1,
      fontSize: 5,
      mt: 2,
    },

    h3: {
      fontFamily: "heading",
      fontWeight: "heading",
      lineHeight: "heading",
      m: 0,
      mb: 1,
      fontSize: 4,
      mt: 3,
    },

    h4: {
      fontFamily: "heading",
      fontWeight: "heading",
      lineHeight: "heading",
      m: 0,
      mb: 1,
      fontSize: 3,
    },

    h5: {
      fontFamily: "heading",
      fontWeight: "heading",
      lineHeight: "heading",
      m: 0,
      mb: 1,
      fontSize: 2,
    },

    h6: {
      fontFamily: "heading",
      fontWeight: "heading",
      lineHeight: "heading",
      m: 0,
      mb: 2,
      fontSize: 1,
    },

    // code: {},
    // pre: {},

    hr: {
      bg: "muted",
      border: 0,
      height: "1px",
      m: 3,
    },
  },
  bg: {
    center: {
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
    },
  },
  links: {
    nav: {
      fontFamily: "Montserrat",
      fontWeight: 200,
      color: 'primary',
      px: 2,
      py: 1,
    }
  },
}

export default theme
